import { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFacebookPages, fetchConnectedPages } from './reducers/connectionsSlice'
import { handleConnect } from './utils'
import BackArrow from './BackArrow'
import { Link } from 'react-router-dom'
import { fetchSocials, fetchUser } from './reducers/userSlice'
import ActionConfirmation from './components/ActionConfirmation'

function FacebookPageChooser() {
  const dispatch = useDispatch()
  const pages = useSelector((state) => state.connections.instagram.allPages)
  const [connectionMade, setConnectionMade] = useState(false)

  useEffect(() => {
    dispatch(fetchSocials())
  }, [])

  const instagramPagesLoaded = useSelector((state) => state.connections.instagram.graphRequestDone)

  const connectedPages = useSelector((state) => state.connections.instagram.connectedPages)

  const connectedPageIds = connectedPages.map((page) => page.platform_account_id)

  const socials = useSelector((state) => state.user.socials)

  const pending_social_id = localStorage.getItem('pending_social_id')

  const pendingSocial = pending_social_id
    ? socials.find((social) => social.id === pending_social_id)
    : null

  console.log('pending social', pendingSocial)

  useEffect(() => {
    dispatch(fetchFacebookPages())
    dispatch(fetchConnectedPages())
    dispatch(fetchUser())
  }, [dispatch])

  const pageOptions = pages.map((page) => {
    return {
      name: page.name,
      account_id: page.instagram_business_account?.id,
      access_token: page.access_token,
    }
  })

  const handlePageRegistration = async ({ id, name, access_token }) => {
    await axios.post('/api/instagram/register-page', {
      instagram_business_account_id: id,
      page_name: name,
      page_access_token: access_token,
      social_account_id: pending_social_id,
    })

    localStorage.removeItem('pending_social_id')
    setConnectionMade(true)

    dispatch(fetchConnectedPages())
  }

  if (!instagramPagesLoaded) {
    return null
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              {pages.length > 0 ? (
                <>
                  {connectionMade ? (
                    <ActionConfirmation />
                  ) : (
                    <>
                      <p
                        style={{
                          fontSize: '1.5rem',
                        }}
                        className="card-title text-center mb-4"
                      >
                        Confirm the page associated with your Instagram account
                      </p>
                      {pendingSocial && (
                        <p className="fw-bold text-center">
                          {pendingSocial?.platform_account_name}
                        </p>
                      )}
                      <p>
                        We use this connection to display media from your Instagram account on your
                        Talent Pitch Pro profile, along with account insights related to your
                        audience demographics and engagement.
                      </p>
                      <hr />
                      <ul className="list-group">
                        {pageOptions.map((page, i) => (
                          <li key={`page-${i}`} className="list-group-item border-0 my-2">
                            {connectedPageIds.includes(page.account_id) ? (
                              <button
                                className="btn btn-dark btn-lg w-100 d-flex align-items-center justify-content-center"
                                disabled
                              >
                                <span className="ms-2">{page.name} (Connected)</span>
                              </button>
                            ) : (
                              <button
                                className="btn btn-outline-primary btn-lg w-100 d-flex align-items-center justify-content-center"
                                onClick={() =>
                                  handlePageRegistration({
                                    id: page.account_id,
                                    name: page.name,
                                    access_token: page.access_token,
                                  })
                                }
                              >
                                <span className="ms-2">{page.name}</span>
                              </button>
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              ) : (
                <ActionConfirmation />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacebookPageChooser
