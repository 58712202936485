import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { siteHeaderLogoUrl } from './config'
import { UserContext } from './UserContext'

function Navbar() {
  // const { user } = useContext(UserContext)
  const user = useSelector((state) => state.user.user)

  const team_id = user.team_id
  const influencer_id = user.influencer_id

  let link = `https://app.talentpitchpro.com/public/profile/${influencer_id}`

  if (team_id) link = link + `/team/${team_id}`

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-3 d-flex justify-content-center justify-content-md-start">
            <Link className="navbar-brand mx-auto mx-lg-0" to="/">
              <img
                style={{
                  maxWidth: '100%',
                  // height: 'auto',
                }}
                src={siteHeaderLogoUrl}
                alt="Talent Pitch Pro Logo"
                // height="30"
              />
            </Link>
          </div>
          <div className="col-12 col-md-7 d-flex justify-content-end">
            {user && (
              <span className="navbar-text mx-auto mx-lg-0 fw-bold">
                <a href={link} target="_blank">
                  {user.fullname}
                </a>
              </span>
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
