import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom'
import axios from 'axios'

function InstagramRedirect() {
  const location = useLocation()
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')
    const errorParam = queryParams.get('error')
    const errorDescription = queryParams.get('error_description')

    const pending_social_id = localStorage.getItem('pending_social_id')

    const provideCode = async () => {
      if (errorParam) {
        console.log('error', errorParam, errorDescription)
        setError(errorDescription)
        return
      }

      const response = await axios.post(`/api/auth/instagram/callback`, {
        code: code,
        social_account_id: pending_social_id,
      })

      // if the response has no error, we should redirect to the `/instagram/pages route
      if (!response.error) {
        setSuccess(true)
        navigate('/instagram/pages')
      } else {
        console.log(response.error)
        setError(response.error)
      }
    }

    provideCode()
  }, [location.search])

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              <div className="text-center">
                {!error ? (
                  <>
                    <h2 className="card-title text-center mb-4">Redirected, verifying response</h2>
                    <span className="visually-hidden">Loading...</span>
                    <div className="spinner-border text-primary" role="status"></div>
                  </>
                ) : (
                  <p className="">
                    Something went wrong while getting authorization. Please{' '}
                    <Link to="/connect/instagram-business">try again</Link> or contact us for
                    assistance.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstagramRedirect
