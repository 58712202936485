import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function ContactUs() {
  const user = useSelector((state) => state.user.user)
  const navigate = useNavigate()

  useEffect(() => {
    if (user.authed) {
      navigate('/')
    }
  }, [user.authed])

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white py-4">
            <div className="card-body">
              <h4 className="card-title text-center mb-4 fw-bold">Authorization Needed</h4>
              <p className="card-text text-center">
                This page is available for authorized users to connect their social accounts. Access is by invitation only.
              </p>
              <p className="card-text text-center">
                If you need access to this page, please contact us at{' '}
                <a href="mailto:erick@talentpitchpro.com">erick@talentpitchpro.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
