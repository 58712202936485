import { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { fetchYoutubeChannels } from './reducers/connectionsSlice'
import { handleConnect } from './utils'
import BackArrow from './BackArrow'
import { Link } from 'react-router-dom'

function YoutubeChannels() {
  const dispatch = useDispatch()
  const channels = useSelector((state) => state.connections.youtube.channels)

  useEffect(() => {
    dispatch(fetchYoutubeChannels())
  }, [dispatch])

  console.log(channels)

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              <p
                style={{
                  fontSize: '1.5rem',
                }}
                className="card-title text-center mb-4"
              >
                Your YouTube channels
              </p>
              <p>
                We use this connection to display media from your YouTube on your Talent Pitch Pro
                profile, along with account insights related to your audience demographics and
                engagement.
              </p>
              <hr />
              {channels.length > 0 ? (
                <>
                  {channels.map((channel, i) => {
                    return (
                      <div key={channel.id} className="mb-3">
                        <div className="row g-0">
                          <div className="col-md-4">
                            <img
                              src={channel.snippet.thumbnails.default.url}
                              className="img-fluid rounded-start"
                              alt="channel"
                            />
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <h5 className="card-title">{channel.snippet.title}</h5>
                              <p className="card-text">{channel.snippet.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                <>No channels</>
              )}
              <p className='text-center'>
                <Link to="/">Home</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YoutubeChannels
