export const handleConnect = (platform) => {
  window.location.href = `/api/auth/${platform}-login`
}

/**
 * Joins an array of class names or any number of class name strings into a single string,
 *    with each class name separated by a space.
 *
 * @param {...(string|null|undefined|string[])} classNames - Either an array of class names or
 *    any number of class name strings, which can include null or undefined values.
 * @returns {string} - A string containing all the class names joined together with a space delimiter.
 */ export const joinClassNames = (...classNames) => {
  if (classNames.length === 1 && Array.isArray(classNames[0])) {
    classNames = classNames[0] // handle the case where a single array argument is passed
  }
  return classNames.filter((className) => !!className).join(' ')
}

export const getSocialAccountName = (social) => {
  if (!social || !social?.platform) return ''

  if (social.platform === 'youtube') return social.platform_account_nickname

  return social.platform_account_name
}
