import React from 'react'
import { Link } from 'react-router-dom'
import BackArrow from './BackArrow'

function NotFound() {
  return (
    <div className="container mt-5">
      <BackArrow />
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white py-4">
            <div className="card-body">
              <h4 className="card-title text-center mb-4 fw-bold">Page Not Found</h4>
              <p className="card-text text-center">
                The requested page does not exist. Please contact us for assistance.
              </p>
              <p className='text-center mt-4'>
                <Link to="/">Home Page</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
