import { Link } from 'react-router-dom'

function ActionConfirmation() {
  return (
    <div className="text-center">
      <h2 className="mb-3">Thank You</h2>
      <p className="">
        That's all for now! We will use the connection to keep your profile updated on our
        platform.
      </p>
      <Link to="/" className="btn btn-link">
        Main Page
      </Link>
    </div>
  )
}

export default ActionConfirmation
