import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { createSelector } from '@reduxjs/toolkit'

const selectInstagramConnectedPages = (state) => state.connections.instagram.connectedPages

export const selectConnectedPageIds = createSelector(
  [selectInstagramConnectedPages],
  (connectedPages) => connectedPages.map((page) => page.platform_account_id),
)

// Async thunk to fetch connected pages
export const fetchConnectedPages = createAsyncThunk(
  'connections/fetchConnectedPages',
  async () => {
    const response = await axios.get('/api/instagram/pages')
    return response.data.pages
  },
)

// Async thunk to fetch Facebook pages
export const fetchFacebookPages = createAsyncThunk('connections/fetchFacebookPages', async () => {
  const response = await axios.get('/api/facebook/pages')
  return response.data.filter((page) => page.instagram_business_account?.id)
})

// Async thunk to fetch TikTok pages
export const fetchTikTokAccounts = createAsyncThunk(
  'connections/fetchTikTokAccounts',
  async () => {
    const response = await axios.get('/api/tiktok/accounts')
    return response.data
  },
)

export const fetchYoutubeChannels = createAsyncThunk(
  'connections/fetchYoutubeChannels',
  async () => {
    const response = await axios.get('/api/youtube/channels')
    return response.data
  },
)

const connectionsSlice = createSlice({
  name: 'connections',
  initialState: {
    instagram: {
      connectedPages: [],
      allPages: [],
      graphRequestDone: false,
    },
    youtube: {
      channels: [],
    },
    tiktok: {
      accounts: [],
    },
    // connectedPages: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConnectedPages.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchConnectedPages.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.instagram.connectedPages = action.payload
      })
      .addCase(fetchConnectedPages.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchFacebookPages.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchFacebookPages.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.instagram.allPages = action.payload
        state.instagram.graphRequestDone = true
      })
      .addCase(fetchFacebookPages.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
        state.instagram.graphRequestDone = true
      })
      .addCase(fetchYoutubeChannels.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.youtube.channels = action.payload
      })
      .addCase(fetchTikTokAccounts.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.tiktok.accounts = action.payload
      })
      .addCase(fetchTikTokAccounts.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export default connectionsSlice.reducer
