import React from 'react'

function Footer() {
  return (
    <footer className="footer mt-auto py-3 bg-light">
      <div className="container text-center">
        <span className="text-muted">
          &copy; 2024{' '}
          <a
            href="https://www.talentpitchpro.com"
            className="text-black"
            target="_blank"
            rel="noopener noreferrer"
          >
            Talent Pitch Pro
          </a>
          . All rights reserved.{' '}
          <a
            href="https://www.talentpitchpro.com/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
        </span>
      </div>
    </footer>
  )
}

export default Footer
