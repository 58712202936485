import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
// import { createSelector } from '@reduxjs/toolkit'

// Async thunk to fetch connected pages
// export const fetchSocials = createAsyncThunk('socials/fetchSocials', async () => {
//   const response = await axios.get('/api/talent/socials')
//   return response.data.socials
// })

const socialsSlice = createSlice({
  name: 'socials',
  initialState: {
    socials: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchSocials.pending, (state) => {
  //       state.status = 'loading'
  //     })
  //     .addCase(fetchSocials.fulfilled, (state, action) => {
  //       state.status = 'succeeded'
  //       state.socials = action.payload
  //     })
  // },
})

export default socialsSlice.reducer
