import { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'
import { fetchUser, loginUser, fetchSocials, fetchTokens } from './reducers/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { joinClassNames } from './utils'

const getSocialAccountName = (social) => {
  if (social.platform === 'youtube') return social.platform_account_nickname

  return social.platform_account_name
}

function SocialStart() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)
  const userFetched = useSelector((state) => state.user.userFetched)
  const loginError = useSelector((state) => state.user.loginError)
  const haveTokens = useSelector((state) => state.user.haveTokens)
  const socials = useSelector((state) => state.user.socials)
  const tokens = useSelector((state) => state.user.tokens)

  useEffect(() => {
    dispatch(fetchUser())
    dispatch(fetchSocials())
    dispatch(fetchTokens())
  }, [])

  useEffect(() => {
    // delete pending_social_id from localStorage when page loads
    localStorage.removeItem('pending_social_id')
  }, [])

  useEffect(() => {
    if (userFetched && _.isEmpty(user.influencer_id)) {
      navigate('/contact-us')
    }
  }, [userFetched, user.influencer_id])

  const socialPlatforms = [
    { name: 'Instagram', icon: (size) => <FaInstagram size={size} />, key: 'instagram' },
    { name: 'TikTok', icon: (size) => <FaTiktok size={size} />, key: 'tiktok' },
    { name: 'YouTube', icon: (size) => <FaYoutube size={size} />, key: 'youtube' },
  ]

  return (
    <div className="container">
      <div className="row mt-2">
        <div className="col-9">
          <p
            style={{
              lineHeight: '1.2',
            }}
            className="fs-2 fw-bold"
          >
            Enhance Your Brand Sales Profile
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-11">
          <p
            style={{
              fontSize: '.85rem',
              color: '#6c757d',
            }}
          >
            We're trusted partners of your management agency, working together to showcase your
            brand and help your manager pitch and secure valuable opportunities. Safely connect
            your social accounts to strengthen your brand profile with real-time verified
            data-driven insights.
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              {/* <p className="card-title text-center mb-4 fs-4 fw-bold">{user ? user.fullname : ''}</p> */}

              {user.authed ? (
                <div className="text-center">
                  <div className="mb-3 d-flex flex-row justify-content-center align-items-center">
                    {user.agency_logo_url && (
                      <div className="px-2">
                        <img
                          src={user.agency_logo_url}
                          style={{ width: '100px', height: 'auto', borderRadius: '15%' }}
                        />
                      </div>
                    )}
                    <div className="px-2">
                      <img
                        src={user.profile_picture_url}
                        style={{ width: '100px', height: 'auto', borderRadius: '15%' }}
                      />
                    </div>
                  </div>
                  {/* <h3 className="mb-3">Connect Your Accounts</h3> */}
                  <ul className="list-group">
                    {socials.map((social, i) => {
                      const platform = socialPlatforms.find((p) => p.key === social.platform)

                      if (!platform) {
                        return null
                      }

                      const connectedSocial = _.find(tokens, { social_account_id: social.id })

                      return (
                        <li key={`social-${i}`} className="list-group-item border-0 my-2">
                          <div className="row justify-content-center">
                            <div className="col-2 col-md-2 text-center align-content-center">
                              {/* {social.picture_main_url && (
                                <img
                                  style={{ width: '100%', borderRadius: '15%' }}
                                  src={social.picture_main_url}
                                />
                              )} */}
                              {platform.icon(30)}
                            </div>
                            <div className="col-10 col-md-6 align-content-center d-flex">
                              <button
                                style={{ height: '100%' }}
                                className={joinClassNames([
                                  `btn`,
                                  'py-2',
                                  connectedSocial
                                    ? 'btn-primary text-white'
                                    : `btn-outline-primary`,
                                  `w-100 d-flex align-items-center justify-content-center`,
                                ])}
                                onClick={() => {
                                  localStorage.setItem('pending_social_id', social.id)
                                  navigate(`/connect/${platform.key}`)
                                }}
                              >
                                <div>
                                  <span className="ms-2">
                                    {connectedSocial
                                      ? `${getSocialAccountName(social)} Connected`
                                      : `Connect ${getSocialAccountName(social)}`}
                                  </span>
                                </div>
                              </button>{' '}
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                  {/* <p className="mt-4">
                    <Link to="/review-authorizations">Review Authorizations</Link>
                  </p> */}
                </div>
              ) : (
                <div className="text-center">
                  {loginError ? (
                    <div className="alert alert-danger" role="alert">
                      {loginError}
                    </div>
                  ) : (
                    <>
                      <h2>Waiting for login...</h2>
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SocialStart
