import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from './Navbar'
import StartPage from './StartPage'
import SocialStart from './SocialStart'
import Hashid from './Hashid'
import InstagramRedirect from './InstagramRedirect'
import FacebookPageChooser from './FacebookPageChooser'
import PlatformConnect from './PlatformConnect'
import ContactUs from './ContactUs'
import YoutubeRedirect from './YoutubeRedirect'
import YoutubeChannels from './YoutubeChannels'
import Footer from './Footer'
import TikTok from './TikTok'
import TikTokRedirect from './TikTokRedirect'
import YouTube from './YouTube'
import LoginPage from './LoginPage'
import { useDispatch } from 'react-redux'
import { fetchUser } from './reducers/userSlice'
import NotFound from './NotFound'
import MatchTokens from './MatchTokens'
import ConnectController from './ConnectController'
import ReviewAuthorizations from './ReviewAuthorizations'

function AppRouter() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Navbar />
        <div className="flex-grow-1">
          <Routes>
            <Route path="/" element={<SocialStart />} />
            <Route path="/start/:loginCode" element={<LoginPage />} />
            <Route path="/instagram/redirect" element={<InstagramRedirect />} />
            <Route path="/instagram/pages" element={<FacebookPageChooser />} />
            {/* <Route path="/connect/instagram-business" element={<InstagramBusiness />} /> */}
            {/* <Route path="/connect/tiktok" element={<TikTok />} /> */}
            {/* <Route path="/connect/youtube" element={<YouTube />} /> */}

            <Route path="/connect/:platform" element={<ConnectController />} />
            <Route path="/tiktok/redirect" element={<TikTokRedirect />} />
            <Route path="/youtube/redirect" element={<YoutubeRedirect />} />
            <Route path="/youtube/channels" element={<YoutubeChannels />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/match/:matchPlatform" element={<MatchTokens />} />
            <Route path="/review-authorizations" element={<ReviewAuthorizations />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export default AppRouter
