import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/userSlice'
import connectionsSlice from './reducers/connectionsSlice'
import socialsSlice from './reducers/socialsSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    connections: connectionsSlice,
    // socials: socialsSlice,
  },
})

export default store
