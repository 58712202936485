const PlatformConnectMenu = ({ socialPlatforms, handleConnect }) => {
  return (
    <ul className="list-group">
      {socialPlatforms.map((platform) => (
        <li key={platform.key} className="list-group-item border-0">
          <button
            className="btn btn-outline-primary btn-lg w-100 d-flex align-items-center justify-content-center"
            onClick={() => handleConnect(platform.key)}
          >
            {platform.icon}
            <span className="ms-2">Begin Authorization</span>
          </button>
        </li>
      ))}
    </ul>
  )
}

export default PlatformConnectMenu
