import { useState, useEffect, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'
import { fetchConnectedPages, fetchFacebookPages } from './reducers/connectionsSlice'
import { useDispatch, useSelector } from 'react-redux'
import BackArrow from './BackArrow'
import PlatformConnectMenu from './components/PlatformConnectMenu'
import ShowKnownAccounts from './ShowKnownAccounts'
import _ from 'lodash'
import { fetchSocials, fetchTokens } from './reducers/userSlice'
import { getSocialAccountName } from './utils'

function ShowPages({ connectedPages }) {
  const dispatch = useDispatch()

  const handleDisconnect = async (platform_account_id) => {
    try {
      await axios.post('/api/instagram/disconnect', { platform_account_id })
      dispatch(fetchConnectedPages())
    } catch (error) {
      console.error('Error disconnecting the account:', error)
    }
  }

  return (
    <div className="connected-pages-section">
      <div className="connected-pages-container">
        <h3 className="mb-4">Your Connected Pages</h3>

        {connectedPages.length > 0 ? (
          connectedPages.map((page, i) => (
            <div
              key={page.platform_account_id}
              className="d-flex justify-content-between align-items-center my-3"
            >
              <span>{page.name}</span>
              <button
                onClick={() => {
                  handleDisconnect(page.platform_account_id)
                }}
                className="btn btn-outline-primary btn-sm"
              >
                Disconnect
              </button>
            </div>
          ))
        ) : (
          <>
            <p>None so far</p>
          </>
        )}

        <div className="mt-4 text-center">
          <Link to="/instagram/pages" className="btn btn-link">
            Connect {connectedPages.length > 0 ? 'another ' : ''}page
          </Link>
        </div>
      </div>
    </div>
  )
}

function PlatformConnect({}) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSocials())
    dispatch(fetchTokens())
  }, [])

  const connectedPages = useSelector((state) => state.connections.instagram.connectedPages)
  const allPages = useSelector((state) => state.connections.instagram.allPages)
  const haveTokens = useSelector((state) => state.user.haveTokens)
  const user = useSelector((state) => state.user)

  const socials = useSelector((state) => state.user.socials)
  const tokens = useSelector((state) => state.user.tokens)
  const params = useParams()

  const { platform } = params

  const knownPlatformSocials = socials.filter((social) => social.platform === platform)

  // When we get pages working, we will show them. For now, just show connected or not.
  // useEffect(() => {
  //   dispatch(fetchConnectedPages())
  //   dispatch(fetchFacebookPages())
  // }, [])

  const handleConnect = (platform) => {
    window.location.href = `/api/auth/${platform}-login`
  }

  const pending_social_id = localStorage.getItem('pending_social_id')

  console.log('pending_social_id', pending_social_id)

  const pendingSocial = pending_social_id
    ? socials.find((social) => social.id === pending_social_id)
    : null

  let socialPlatforms = [
    { name: 'Instagram', icon: <FaInstagram />, key: 'instagram' },
    { name: 'TikTok', icon: <FaTiktok />, key: 'tiktok' },
    { name: 'YouTube', icon: <FaYoutube />, key: 'youtube' },
  ]

  socialPlatforms = socialPlatforms.filter((item) => item.key === platform)

  const selectedPlatform = _.find(socialPlatforms, { key: platform })

  if (!user.userFetched) {
    return 'Loading...'
  }

  const connectionsKey = {
    instagram: 'Facebook/Instagram',
    tiktok: 'TikTok',
    youtube: 'YouTube',
  }

  const platformTokens = tokens.filter((token) => token.platform === platform)

  const tokensNeedId = (tokens) => {
    // if any tokens are missing the `social_account_id` property, return true
    return tokens.some((token) => !token.social_account_id)
  }

  const selectedPlatformHasToken = useCallback(() => {
    if (!pendingSocial || tokens.length === 0) {
      return false
    }

    return tokens.some((token) => token.social_account_id === pendingSocial.id)
  }, [])()

  console.log('platform has token?', selectedPlatformHasToken)

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              <div className="text-center">
                <h2 className="mb-3">
                  <span className="me-2">{selectedPlatform.icon}</span>
                  {selectedPlatform.name}
                </h2>
                {/* <ShowKnownAccounts socials={knownPlatformSocials} platform={platform} />
                <hr /> */}
                {selectedPlatformHasToken ? (
                  <>
                    <p>
                      You connected your account, {getSocialAccountName(pendingSocial)}. Thanks!
                    </p>
                    {/* <div className='mt-4'>
                      <button onClick={() => handleConnect(platform)} className='btn btn-primary text-white'>Confirm</button>
                    </div> */}
                    {/* <p>
                      <button
                        onClick={() => handleConnect(platform)}
                        className="btn btn-primary text-white"
                      >
                        Re-connect
                      </button>
                    </p> */}
                    <p>
                      Problem? <a href="mailto:erick@talentpitchpro.com">Contact us</a>.
                    </p>
                    {/* <p>
                      <Link to="/match/instagram">Match to Platform</Link>
                    </p> */}
                    <p>
                      <Link to="/">Home</Link>
                    </p>
                  </>
                ) : (
                  <>
                    <p>Connect {getSocialAccountName(pendingSocial)}</p>
                    <PlatformConnectMenu
                      socialPlatforms={socialPlatforms}
                      handleConnect={handleConnect}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlatformConnect
