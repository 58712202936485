import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

function BackArrow({ to }) {
  const navigate = useNavigate()

  const navTo = to ? to : -1

  return (
    <button
      className="btn btn-link"
      onClick={() => navigate(navTo)}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <FaArrowLeft size={20} />
    </button>
  )
}

export default BackArrow
