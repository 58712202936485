import React from 'react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './styles/index.scss'
import AppRouter from './AppRouter'
import { UserProvider } from './UserContext'
import { Provider } from 'react-redux'
import store from './store'
import { fetchUser } from './reducers/userSlice'

store.dispatch(fetchUser())

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <AppRouter />
  </Provider>,
)
